/* override bootstrap */
#bootstrap-overrides {
    line-height: 1; 
}

#bootstrap-overrides p {
  font-family: Times New Roman, "Georgia", sans-serif;
}

.hidden {
  display: none;
}

.App {
  text-align: center;
  position: relative;
}

.nav-title {
  font-size: 4.7em;
  font-family: "Georgia", Times New Roman, sans-serif;
  display: inline-block;
  margin: 10px 0 10px 0;
}

.nav-title > a {
  text-decoration: none;
  color: red;
  transition: 0.3s;
}

.nav-title > a:hover {
  color: black;
  border-radius: 1000px; 
  background-color: white; 
  padding: 20px;
  transition: 0.3s;
}

#navbar {
  background: rgba(0, 0, 0, 0.8); 
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 2; 
}

.navbar-items {
  display: flex;
  justify-content: space-between;
}

#burger-menu {
  display: none;
}

.burger-stripe {
  width: 40px;
  height: 5px;
  background-color: gray; 
  margin: auto;
  border-radius: .15em;
}

.nav-li {
  flex: 1;
  margin-top: 2.4em;
  margin-bottom: 2em;
  display: inline-block;  
  transition: .3s;
}

.nav-li > a:hover {
  color: black;
  padding: 50px;
  border-radius: 100px; 
  background-color: white; 
  transition: 0.3s;
}

.nav-li > a {
  font-size: 1.8em;
  font-family: Times New Roman, "Georgia", sans-serif;
  text-decoration: none;
  padding: 0em;
  padding-bottom: 0em;
  margin: 0em;
  color: white;
  z-index:1;
  transition: 0.3s;
}

@media only screen and (max-width: 768px) {
  .navbar-items .nav-li {
    flex-direction: column;
  }
/*  .nav-li-closed {
    display: none;
  }*/

  #burger-menu {
    display: inline-block;
    margin: 20px 20px;
    border-radius: .5em; 
    border: 1px solid gray;
    width: 3em;
    height: 3em;
    display: flex;
    flex-direction: column; 
    justify-content: space-around;
    cursor: pointer;
  }
}

.footer {
  margin: 15em auto;
  /* top: 90vh; */
  font-size: 3px; 
  text-align: center; 
  /* display: inline; */
}

.txt-container {
  text-align: center; 
  width: 75%;
  margin: 0 auto;
}

.txt-title {
  font-family: "Georgia", Times New Roman, sans-serif;
  font-size: 5em;
  color: white;
}

.txt-sub-title {
  font-size: 3em;
}

.txt-body {
  font-family: Times New Roman, "Georgia", sans-serif;
  font-size: 1.5em; 
  color: white;
}

.txt-body-left {
  text-align: left; 
}

.txt-small {
  font-size: 1em;
}

.standard-link {
  color: white;
  text-decoration: underline; 
}

/* --- file: components/index.component.js --- */
body {
  background-size: cover;
  background-attachment: fixed; 
}

p {
  color: #fff;
  font-size: 4em; 
  z-index: 100;
 }

span {
  color: #fff;
}

@keyframes fadeIn {
  0%: {opacity: 0;}
  100%: {opacity: 1;}
}

.background-img img {
  right: 0;
  top: 2em;
  position: absolute;
  width: 100%;
  overflow: hidden;
  z-index: -1;
}

.motto {
  margin: 70vh 0 0 0;
}

.submotto {
  margin: 1em 0 0 0;
  font-size: 1em;
}

.enter-theater-btn {
  font-family: "Georgia", Times New Roman, sans-serif;
  font-size: 2em;
  color: white;
  margin: 2em 0 2em 0;
  padding: 1em;
  background-color: black;
  border: 1px solid white;
  border-radius: 2em;
  transition: .3s;
}

.enter-theater-btn:hover {
  color: black;
  border: 1px solid black;
  background-color: #ffd333;
  padding: 1em 3em 1em 3em;
  transition: .3s;
}

/* --- file: components/videos.component.js --- */
/* why does .background-img class connect to videos.component? */
body {
  background-color: black; 
}

.page-container {
  display: flex;
}

.video-container {
  background-color: #000; 
  flex: 6;
}

.films-title {
  color: white;
  font-family: "Georgia", Times New Roman, sans-serif;
  font-size: 5em;
  margin: .5em 0 .5em 0;
}

.sidebar {
  flex: 1.2;
  border-right: 3px solid white;
}

.sidebar ul {
  padding: 0;
  top: 50%;
}

.sidebar li {
  position: fixed;
  display: flex;
  font-size: 2vw; 
}

.sidebar a {
  color: red;
  text-decoration: none; 
  transition: 0.4s;
}

.sidebar a:hover {
  color: #ffd333;
  font-size: 2.2vw; 
  transition: 0.4s;
}

.sticky-elements {
  position: sticky;
  margin: 23% 0 0 0;
  top: 20%;
  height: 400px;
}

.searchbar-container {
}

.searchbar {
  border: none;
  margin: 0 10px 0 10px;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  outline: none;
}

/*TODO: REMOVE OR CHANGE TO SPINNER*/
.vid-load {
  font-size: 100px;
  background-color: green; 
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
  max-height: 50vmin;
  overflow: auto;
}

.chosen-tags {
  max-height: 60px;
  overflow: auto;
}

.tag, .chosen-tag {
  background-color: gray;
  margin: 2px;
  padding: 0.2em;
  border-radius: 1em;
  cursor: pointer;
  transition: 0.3s;
}

.tag p, .chosen-tag p {
  font-size: 0.8em;
  display: inline;
}

.tag:hover {
  background-color: #ffd333;
}

.tag:hover p {
  color: black;
}

.chosen-tag:hover {
  background-color: red; 
  transition: 0.3s;
}

.chosen-tag:hover p {
  display: none;
}

.chosen-tag-remove {
  display: none;
  pointer-events: none; 
  font-size: .8em;
}

.chosen-tag:hover .chosen-tag-remove {
  display: inline;
}

.nomatch {
  color: white;
  font-size: 3em;
  margin: 25% auto;
}

/* --- file: /components/videopage.js --- */
.videopage {
  display: flex; 
  flex-direction: column; 
}

.movie {
  margin: 4em 0 0 0;
}

.videopage-title {
  margin: 1em 0 0 0;
  display: block;
}

.videopage-description {
  font-size: 25px; 
}

/* --- file: components/studio.component.js --- */
.top-img {
  margin: 5em 0 0 0;
  padding: 0;
  transition: .3s;
  display: inline-block;
}

.top-img:hover {
  transform: rotate(20deg);
  transition: .3s;
}

/* --- file: components/mission.component.js --- */
.mission-text {
  margin-top: 65vh;
  margin-bottom:5vh;
}

.mission-text-small {
  font-size: 5vh;
  margin-bottom: 10vh;
}

.mission-link {
  color: white;
}

.mission-container {
  width: 80%;
  margin: auto auto;
}

/* --- file: components/mission.component.js --- */
.contact-text {
  margin-top: 70vh;
  margin-bottom: 10vh;
  font-size: 2em;
}

/* --- file: pieces/VideoThumbnail.js --- */
.video-thumbnail {
  width: 280px;
  margin: 0 20px 0px 20px;
  text-decoration: none; 
  position: relative;
  display: inline-block;
  vertical-align: top; 
}

.video-thumbnail a {
  text-decoration: none; 
}

.video-thumbnail-img {
  overflow: hidden;
}

.video-thumbnail-img img {
  width: 275px; 
  height: 200px;
  overflow: hidden;
  transition: transform 0.4s;
}

.video-thumbnail:hover img {
  transform: scale(1.25);
}

.video-thumbnail:hover .thumb-desc {
  pointer-events: none;
  visibility: visible; 
  top: 10%;
}

.thumb-text {
  pointer-events: none;
  z-index: 1;
  text-align: center; 
}

.thumb-title {
  font-size: 20px;
  word-wrap: break-word;
  transition: 0.4s;
}

.video-thumbnail:hover .thumb-title {
  color: #ffd333;
}

.thumb-desc {
  font-size: 20px;
  visibility: hidden; 
  position: absolute;
}

